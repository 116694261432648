<template>
  <div class="ride-with-us">
    <PageHeading
      :title="'Streamlining Patient Transit for Healthcare Providers'"
      :text="'MedDrive.Health provides seamless patient management who require rides, ensuring access to your healthcare providers’ offices with just a click.  Discover how our service will enhance overall patient satisfaction with your office and reduce the number of “No Shows”.'"
    />
    <RowBlock :items="services" />
    <TimelineBlock :items="howItWorks" :title="'How it works'" />
  </div>
</template>

<script>
import PageHeading from "../components/PageHeading.vue";
import TimelineBlock from "../components/TimelineBlock.vue";
import RowBlock from "../components/RowBlock.vue";

export default {
  name: "RideForProviders",
  components: {
    PageHeading,
    TimelineBlock,
    RowBlock,
  },
  data: () => ({
    howItWorks: [
      {
        img: "images/providers/providers-itswork-1.png",
        title: `<a href="/register" style="text-decoration: underline;">Sign Up</a> with MedDrive.Health`,
        text: "Providers office will register for MedDrive online portal to schedule transportation for their patients, by providing essential details such as patient needs, language preferences, round trip and more.",
      },
      {
        img: "images/providers/providers-itswork-2.jpg",
        title: "MedDrive.Health will assign a driver",
        text: "The designated driver will introduce themselves, verify the pick-up time and location a day in advance, and reaffirm the details to the patient with a supporting message from providers office.",
      },
      {
        img: "images/providers/providers-itswork-3.jpg",
        title: "Monitor Status",
        text: "Track your patient’s journey with ease through our user portal, and communicate with the driver at any point during the trip should there be any changes.",
      },
    ],
    services: [
      {
        img: "images/providers/providers-services-1.png",
        //title: "Lorem ipsum dolor",
        text: "Our scheduling portal allows multiple users under a single provider's office to have access, with one assigned admin for seamless account management.",
      },
      {
        img: "images/providers/providers-services-2.png",
        //title: "Lorem ipsum dolor sit amet",
        text: "Drivers verify pick-up times a day prior and swiftly inform the office of any patient updates, ensuring smooth communication and reducing no-shows.",
      
      },
      {
        img: "images/providers/providers-services-3.jpg",
        //title: "Lorem ipsum dolor sit amet consectetur",
        text: "Users, having access to driver information and real-time status on the ride, can instantly communicate with drivers in case of any changes from the office side.",
      
      },
      {
        img: "images/providers/providers-services-4.png",
        //title: "Lorem ipsum dolor",
        text: "Drivers remain on standby, guaranteeing a maximum of 15-minute wait time, and in most cases, are waiting for patients upon appointment completion.",
      
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
 .ride-with-us {
  display: flex;
  flex-flow: column;
  align-items: center;
  
  &__main {
    background: $primary-color;
  }

  &__contact-us {
    width: 100%;
    padding: 120px 25px;

    background: #ffffff;

    position: relative;
    z-index: 2;

    @media screen and (max-width: 940px) {
      padding: 60px 25px;
    }
  }
}
</style>